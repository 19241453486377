module.exports = function () {
	setInterval(() => {
		const first = document.querySelector('.carousel img');
		const active = document.querySelector('.carousel img.active');
		if (!active) {
			document.querySelector('.carousel img').classList.add('active');
			return;
		}
		const sibling = active.nextElementSibling;
		if (sibling) {
			sibling.classList.add('active');
		} else {
			first.classList.add('active');
		}
		active.classList.remove('active');
	}, 1000);
};