module.exports = function () {
	const tag = document.createElement('script');
	tag.src = "https://www.youtube.com/iframe_api";
	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	let player;
	window.onYouTubeIframeAPIReady = () => {
		player = new YT.Player('youtube', {
			height: '560',
			width: '315',
			videoId: 'SBPTs6GrSSM',
			events: {
				'onReady': onPlayerReady,
			}
		});
	}
	const onPlayerReady = event => document.querySelector('.youtube-preview').classList.add('active');
	const playVideo = () => player.playVideo();
	const stopVideo = () => player.stopVideo();

	if (window.innerWidth >= 768) {
		$('.youtube-preview').addEventListener('click', (e) => {
			e.preventDefault();
			$('.lightbox').classList.add('active');
			playVideo();
		});

		$('.lightbox a').addEventListener('click', (e) => {
			e.preventDefault();
			$('.lightbox').classList.remove('active');
			stopVideo();
		});
	}
};
