module.exports = function () {
	$('.ability').forEach(el => {
		el.addEventListener('mouseover', e => {
			$('.ability').forEach(el2 => {
				el2.classList.remove('active');
			});

			const title = e.target.getAttribute('title');
			$('.ability-title').innerHTML = title;
		});
	});
	$('.abilities-list').addEventListener('mouseleave', e => {
		const firstAbilite = $('.ability-freeze');
		const title = firstAbilite.getAttribute('title');
		firstAbilite.classList.add('active');
		$('.ability-title').innerHTML = title;
	});
};
