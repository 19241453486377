{
	require('./modules/helpers.js')();
	require('./modules/youtube.js')();
	require('./modules/reveal.js')();
	require('./modules/carousel.js')();
	require('./modules/parallax.js')();
	require('./modules/abilities.js')();
	require('./modules/tracking.js')();

	/* particlesJS.load(@dom-id, @path-json, @callback (optional)); */
	particlesJS.load('particles-js', '/js/particles.json', function() {
		// console.log('callback - particles.js config loaded');
	});
}
