module.exports = function () {
	if (window.innerWidth >= 768) {
		window.sr = ScrollReveal();
		sr.reveal('.reveal-left', { origin: 'left', beforeReveal: domEl => {
			const label = domEl.getAttribute('data-reveal-name');
			ga('send', 'event', 'Reveal', 'Scroll', label);
		} });
		sr.reveal('.reveal-top', { origin: 'top', delay: 100 });
	}
};
